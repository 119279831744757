<template>
    <userDetails page="my_account"></userDetails>
</template>

<script>
    import {mapState} from 'vuex'
    import userDetails from '@/components/UserDetails'

    export default {
        name: 'User',

        components: {
            userDetails
        },

        computed: {
            ...mapState(['isLoggedIn']),
        },

        created() {
            // Route guard
            if (!this.isLoggedIn) {
                this.$store.dispatch('logUserOut')
                this.$router.push('/login')
            }
        },
    };
</script>
